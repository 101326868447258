const ROUTER_PATH = {
  SIGN_IN: "/sign-in",
  HOME_PAGE: "/",
  PATIENT_DIAGNOSIS: "/patient-diagnosis",
  THANK_YOU: "/thank-you",
  VITALS_MONITORING: "/vitals-monitoring",
  START_CHAT: "/start-chat",
};

const BASE_URL = "https://gppoddev.xeventechnologies.com";
// const BASE_URL = "https://gppodbe.innotech.xeventechnologies.com";

const API_URLS = {
  USER: {
    GENERATE_ID: BASE_URL + "/generate_random_no",
  },

  CHAT: {
    WELCOME: BASE_URL,
    PROMPT_RESPONSE: BASE_URL + "/generate_prescription_from_chat_session",
    AUDIO_FROM_TEXT: BASE_URL + "/text_to_speech",
    SAVE_USER_CHAT: BASE_URL + "/user/save_history_name/",
    GET_CHAT_HISTORY: BASE_URL + "/get_chat_history",
  },

  VITALS_MONITORING: {
    GET_BLOOD_PRESSURE: BASE_URL + "/blood_pressure_random",
    GET_BLOOD_OXYGEN: BASE_URL + "/blood_oxygen_random",
    GET_PULSE_RATE: BASE_URL + "/pulse_rate_random",
    GET_TEMPERATURE: BASE_URL + "/temperature_random",
    GET_GLUCOSE: BASE_URL + "/glucose_random",
    GET_WEIGHT: BASE_URL + "/weight_random",
  },
};

export { API_URLS, BASE_URL, ROUTER_PATH };
